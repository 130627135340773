a{
  text-decoration: none !important;
}
.pointer{
  cursor: pointer;
}
.user-select{
    border: 1px solid #f0f1f5 !important;
    padding-top: 16px !important;
    padding-bottom: 16px !important;
    border-radius: 12px !important;
}
select:required:invalid {
    color: gray;
  }
  .css-yk16xz-control{
    padding-top: 9px !important;
    padding-bottom: 9px !important;
    padding-left: 10px !important;
    border: 1px solid #f0f1f5 !important;
    border-radius: 12px !important;

}
.css-1pahdxg-control{
    padding-top: 9px !important;
    padding-bottom: 9px !important;
    border-color: #2684ff !important;
    border-radius: 12px !important;
    box-shadow: 0 0 0 0 #2684ff !important;
}
.react-switch {
    vertical-align: middle;
    margin-left: 4px;
  }
  .text-font-style{
    font-size: 18px;
    font-weight: 600;
    color: black;
  }

  .react-tel-input{
    width: auto !important;
  }
  .react-tel-input .special-label {
    display: none;
    position: absolute;
    z-index: 1;
    font-size: 13px;
    left: 25px;
    top: -7px;
    background: #fff;
    padding: 0 5px;
    white-space: nowrap;
}
  .react-tel-input .form-control{
    width: 110px !important;
    font-size: 14px !important;
    border-radius: 9px !important;
    border: 1px solid #f0f1f5 !important;
    padding: 18.5px 9px 18.5px 52px !important;
  }
  .react-tel-input .country-list{
    width: 250px !important;
  }
  .search-input {
    padding-bottom: 25px !important;
    padding-top: 25px !important;
    border-radius: 10px !important;
    font-size: 14px !important;
  }


.appointment-card-container {
  overflow-x: scroll; 
  display: flex;
  max-width: 100%;
  padding: 10px;
}

.appointment-card {
  min-width: 600px;
  flex-shrink: 0;
  padding: 10px;
}

.image-container {
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cross-icon {
  top: -6px;
  position: absolute;
  right: -6px;
  z-index: 1;
}